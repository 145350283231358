import Glide, { Controls, Breakpoints, Swipe, Images, Anchors } from '@glidejs/glide/dist/glide.modular.esm';

const sliders = document.querySelectorAll('.carousel');
for (let i = 0; i < sliders.length; i++) {

	const perView = sliders[i].getAttribute('data-carousel-view');
	const mobilePerView = sliders[i].getAttribute('data-carousel-mobile-view');
	const tabletPerView = sliders[i].getAttribute('data-carousel-tablet-view');
	const type = sliders[i].getAttribute('data-carousel-type');

  const glide = new Glide(sliders[i], {
    type: type,
		perView: perView,
		rewind: false,
		breakpoints: {
			1280: {
				perView: tabletPerView
			},
			1024: {
				perView: mobilePerView
			}
		},
  });
  glide.mount({ Controls, Breakpoints, Swipe, Images, Anchors })
}
