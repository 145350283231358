import Vue from "vue";
import svg4everybody from "svg4everybody";
import polyfill from "./polyfills";
import initModules from "./lib/init-modules";
import modulesEnabled from "./modulesEnabled";
import MicroModal from "micromodal"

Vue.config.productionTip = false;

const setJsDocumentClasses = () => {
  const docEl = document.documentElement;

  docEl.classList.remove("js-loading");
	docEl.classList.add("js-loaded");
};

const init = (APP) => {
  APP.DEBUG = typeof APP.DEBUG !== "undefined" ? APP.DEBUG : !process.env.NODE_ENV === "production";
	APP.modules = initModules(APP.modules, modulesEnabled, APP.DEBUG);
};

window.addEventListener("DOMContentLoaded", () => {
  setJsDocumentClasses();
	svg4everybody();
	MicroModal.init();

  polyfill().then(() => {
		init(window.APP);
  });
});

// HTML Forms Tracking
var forms = document.querySelectorAll('.hf-form');

forms.forEach(function(el) {
    el.addEventListener('hf-submit', function (event) {
        var form = event.target;
        var formID = form.getAttribute('data-id');
        if(formID == '403') { // change for your form ID
            gtag('event', 'click', {'event_category': 'Contact Form','event_action': 'Send Form','event_label': 'Successful Contact Form Enquiry'});
        } else if(formID == '402') { // change for your form ID
            gtag('event', 'click', {'event_category': 'Quick Quote Form','event_action': 'Send Form','event_label': 'Successful Quick Quote Form Enquiry'});
        }
    }, false);
});