<template>
	<h6 :id="id" class="w-full block mb-0 mt-0">
		<button
			:aria-controls="'accordion-panel-' + id"
      :aria-expanded="isExpanded"
      :aria-label="ariaLabel + ' ' + toggleLabel"
      :class="[...htmlClasses, isExpanded ? 'is--active' : '']"
      class="block btn btn--reset no-underline w-full border-b border-grey py-4 px-4 flex border-b text-lg"
      @click.prevent="toggle">
				<span v-html="text" />
				<div class="ml-auto">
					<font-awesome-icon class="text-primary" :icon="['far', expandIcon]"></font-awesome-icon>
				</div>
		</button>
	</h6>
</template>
<script>
import ToggleBase from "./ToggleBase";

export default {
	extends: ToggleBase,
};
</script>
