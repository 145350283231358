var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "relative" }, [
    _vm.item.children
      ? _c("div", [
          _c("div", { staticClass: "flex" }, [
            _c("a", {
              staticClass:
                "nav-item flex-1 has--dropdown border-b border-grey-light",
              class: _vm.item.class,
              attrs: {
                href: _vm.item.url,
                "aria-haspopup": "true",
                tabindex: "0"
              },
              domProps: { innerHTML: _vm._s(_vm.item.title) }
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn--reset ml-auto p-3 bg-grey-light",
                on: { click: _vm.toggle }
              },
              [
                _c("font-awesome-icon", {
                  attrs: { icon: ["far", "angle-down"] }
                })
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.isExpanded
            ? _c(
                "ul",
                { attrs: { "aria-hidden": "true", "aria-label": "subnav" } },
                _vm._l(_vm.item.children, function(item) {
                  return _c("li", { key: item.id }, [
                    _c("a", {
                      staticClass: "nav-item bg-grey-light",
                      class: item.class,
                      attrs: { href: item.url, tabindex: "0" },
                      domProps: { innerHTML: _vm._s(item.title) }
                    })
                  ])
                }),
                0
              )
            : _vm._e()
        ])
      : _c("div", [
          _c(
            "a",
            {
              staticClass: "nav-item",
              class: _vm.item.class,
              attrs: { href: _vm.item.url, tabindex: "0" }
            },
            [_vm._v(_vm._s(_vm.item.title))]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }